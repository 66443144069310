/* src/components/PairingOverview.css */

.pairing-overview {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.strategy-section {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

h3 {
  color: #333;
}

h4 {
  color: #555;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 5px 0;
}

p {
  font-size: 16px;
  font-weight: bold;
}

.remaining-cash {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-top: 30px;
  background-color: #f9f9f9;
}
